export interface ILogin {
	username?: string;
	password?: string;
	usernameError?: string;
	passwordError?: string;
}

export class UserLoginModel {
	username?: string;
	password?: string;
	usernameError?: any;
	passwordError?: any;

	constructor(data?: ILogin) {
		this.username = data?.username || "";
		this.password = data?.password || "";
	}
}

export interface ICode {
	code?: string;
	token?: string;
	codeError?: string;
}

export class CodeModel {
	code?: string;
	token?: string;
	codeError?: string;

	constructor(data?: ICode) {
		this.code = data?.code || "";
		this.token = data?.token || "";
	}
}
