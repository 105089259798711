import Logo from "../../icons/logo";
import { FloatingBlock } from "../../../styles/blocks";
import LoginForm from "../../forms/account/login";
import { H1 } from "../../../styles/type/headings";
import { AssistiveText } from "../../../styles/type/typography";

const Login = ({ redirect }) => (
	<>
		<FloatingBlock>
			<H1 small>hello.</H1>
			<Logo />
			<AssistiveText pushTop>The professional network in education.</AssistiveText>
			<LoginForm redirect={redirect} />
		</FloatingBlock>
	</>
);

export default Login;
