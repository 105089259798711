import React, { useState, useEffect } from "react";
import { Props } from "../../../_interfaces/component.inputText.interface";
import { FormRow, FormRowInner, Label } from "../../../styles/forms/inputs";
import InputError from "../errors/input";

const EmailInput: React.FC<Props> = ({ label = false, light = false, help = false, placeholder = null, name, value, readonly = false, error = null, inline = false, onChange, required = false }) => {
	let timeout: any = null;

	const [inputValue, setInputValue] = useState(value);
	const [inputError, setInputError] = useState(error);

	const handleChange = (e) => {
		e.persist();
		setInputValue(e.target.value);
		timeout = setTimeout(() => {
			clearTimeout(timeout);
			onChange(e);
		}, 100);
	};

	const handleBlur = (e) => {
		onChange(e);
	};

	useEffect(() => {
		setInputError(error);
	}, [error]);

	return (
		<FormRow hasError={inputError ? true : false}>
			<FormRowInner>
				{label && (
					<Label light={light ? true : false} help={help}>
						{label}
						{required ? "*" : null}
					</Label>
				)}
				<input
					type="password"
					placeholder={!label ? placeholder || "" : ""}
					name={name}
					value={inputValue}
					onChange={(e) => handleChange(e)}
					onBlur={(e) => handleBlur(e)}
					readOnly={readonly ? true : false}
					autoCapitalize="none"
				/>
			</FormRowInner>
			{!inputError ? null : <InputError message={inputError} inline={inline} />}
		</FormRow>
	);
};
export default EmailInput;
