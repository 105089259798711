import { HTTP, IHttpResponse } from "./http";
import {
	CHECK_AUTH_SESSION,
	CHECK_PRE_AUTH_SESSION,
	REMOVE_AUTH_SESSION,
	LOGIN,
	CODE_LOGIN_PROXY,
	CODE_LOGIN,
	COMPLETE_LOGIN,
	ACTIVATE_ACCOUNT_1,
	ACTIVATE_ACCOUNT_2,
	RESET_PASSWORD_1,
	RESET_PASSWORD_2,
	LOGIN_PROXY,
} from "../../config/endpoints";
import { HeadersAuth, HeadersAuthBasic, HeadersNoAuth } from "./http";
import { ICode } from "../../_models/data.userLogin.model";

export const CheckAuthSession = async (Token: string, DATA) => {
	return HTTP({
		Method: "POST",
		Url: CHECK_AUTH_SESSION,
		Data: DATA ?? null,
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const CheckPreAuthSession = async (Token: string) => {
	return HTTP({
		Method: "POST",
		Url: CHECK_PRE_AUTH_SESSION,
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const RemoveAuthSession = async (Token: string, DATA) => {
	return HTTP({
		Method: "POST",
		Url: REMOVE_AUTH_SESSION,
		Data: DATA ?? null,
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const ActivateAccount2 = async (DATA) => {
	return HTTP({
		Method: "POST",
		Url: ACTIVATE_ACCOUNT_2,
		Data: DATA ?? null,
		Headers: HeadersNoAuth,
	}) as Promise<IHttpResponse>;
};

export const ActivateUserAccount1 = async (DATA) => {
	return HTTP({
		Method: "POST",
		Url: ACTIVATE_ACCOUNT_1,
		Data: DATA ?? null,
		Headers: HeadersNoAuth,
	}) as Promise<IHttpResponse>;
};

export const LoginProxy = (DATA) => {
	return HTTP({
		Method: "POST",
		Url: LOGIN_PROXY,
		Data: DATA ?? null,
		Headers: HeadersNoAuth,
	}) as Promise<IHttpResponse>;
};

export const Login = (DATA) => {
	return HTTP({
		Method: "POST",
		Url: LOGIN,
		Data: DATA ?? null,
		Headers: HeadersNoAuth,
	}) as Promise<IHttpResponse>;
};

export const CodeLoginProxy = (DATA: ICode) => {
	return HTTP({
		Method: "POST",
		Url: CODE_LOGIN_PROXY,
		Data: DATA ?? null,
		Headers: HeadersNoAuth,
	}) as Promise<IHttpResponse>;
};

export const CodeLogin = (DATA: ICode) => {
	return HTTP({
		Method: "POST",
		Url: CODE_LOGIN,
		Data: DATA ?? null,
		Headers: HeadersNoAuth,
	}) as Promise<IHttpResponse>;
};

export const CompleteLogin = (SESSION_ID, PRE_AUTH_TOKEN) => {
	return HTTP({
		Method: "POST",
		Url: COMPLETE_LOGIN,
		Headers: HeadersAuthBasic(SESSION_ID, PRE_AUTH_TOKEN),
	}) as Promise<IHttpResponse>;
};

export const Reset1 = (DATA) => {
	return HTTP({
		Method: "POST",
		Url: RESET_PASSWORD_1,
		Data: DATA ?? null,
		Headers: HeadersNoAuth,
	}) as Promise<IHttpResponse>;
};

export const Reset2 = (DATA) => {
	return HTTP({
		Method: "POST",
		Url: RESET_PASSWORD_2,
		Data: DATA ?? null,
		Headers: HeadersNoAuth,
	}) as Promise<IHttpResponse>;
};
