import { useEffect } from "react";
import { useRouter } from "next/router";

/**
 * Custom hook to prevent back navigation in sensitive pages
 *
 * This hook:
 * 1. Replaces the current history entry with the home page URL
 * 2. Adds an event listener for the back button that prevents the default action
 * 3. Redirects to the home page if a user somehow manages to navigate back
 *
 * @param {string} redirectPath - The path to redirect to when back navigation is attempted (defaults to '/')
 */
const usePreventBackNavigation = (redirectPath = "/") => {
	const router = useRouter();

	useEffect(() => {
		// Only run on client side
		if (typeof window === "undefined") return;

		// Replace the current history entry instead of adding a new one
		// This effectively removes the page from history
		window.history?.replaceState(null, "", redirectPath);

		// Handle back button press
		const handleBackButton = (e) => {
			// Prevent the default back action
			e.preventDefault();

			// Redirect to specified path
			router.replace(redirectPath);

			// Return false to prevent default behavior
			return false;
		};

		// Add event listener for back button
		window.addEventListener("popstate", handleBackButton);

		// Clean up
		return () => {
			window.removeEventListener("popstate", handleBackButton);
		};
	}, [router, redirectPath]);
};

export default usePreventBackNavigation;
